import { Injectable } from '@angular/core';
import { RouterActions } from '@apx-ui/apx-core';
import { NotificationActions } from '@apx-ui/apx-shared-ui';
import { ApxSolsticeWebClientService } from '@apx-ui/apx-web-api-v1';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AssetActions, SuspendActions } from '../actions';

@Injectable()
export class SuspendEffects {

  handleSuspendContinuousTreatment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SuspendActions.suspendContinuousTreatment,
      ),
      switchMap(({ accountId, locationId, treatment, reasonForm, startAt, endAt, unitSystemId }) => {

        const data = {
          treatmentType: 'continuous',
          treatmentId: +treatment.Id,
          locationId,
          reasonId: reasonForm.reason.Id,
          customPauseReason: reasonForm.comment,
          startDate: startAt,
          endDate: endAt,
        };

        return this.client.setSuspendTreatment(data, accountId, unitSystemId, {}).pipe(
          switchMap(asset => {

            return [
              AssetActions.addAsset({ asset }),
              SuspendActions.suspendContinuousTreatmentSuccess({
                accountId, treatment, reasonForm, startAt, endAt,
              }),
              NotificationActions.success({
                message: 'Successfully set to suspend.',
              }),
            ];
          }),
          catchError(err => of(
            SuspendActions.suspendContinuousTreatmentFailure({
              accountId, treatment, reasonForm, startAt, endAt, err,
            }),
            NotificationActions.error({
              message: 'Cannot set to suspend.',
            }),
          )),
        );
      }),
    ),
  );

  handleSuspendBatchTreatment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SuspendActions.suspendBatchTreatment,
      ),
      switchMap(({ accountId, locationId, treatment, reasonForm, startAt, endAt, unitSystemId }) => {

        const data = {
          treatmentType: 'batch',
          treatmentId: treatment.Id,
          locationId,
          reasonId: reasonForm.reason.Id,
          customPauseReason: reasonForm.comment,
          startDate: startAt,
          endDate: endAt,
          unitSystemId,
        };

        return this.client.setSuspendTreatment(data, accountId, unitSystemId, {}).pipe(
          switchMap(asset => {

            return [
              AssetActions.addAsset({ asset }),
              SuspendActions.suspendBatchTreatmentSuccess({
                accountId, treatment, reasonForm, startAt, endAt,
              }),
              NotificationActions.success({
                message: 'Successfully set to suspend.',
              }),
            ];
          }),
          catchError(err => of(
            SuspendActions.suspendBatchTreatmentFailure({
              accountId, treatment, reasonForm, startAt, endAt, err,
            }),
            NotificationActions.error({
              message: 'Cannot set to suspend.',
            }),
          )),
        );
      }),
    ),
  );

  handleSuspendAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SuspendActions.suspendAsset,
      ),
      switchMap(({ accountId, locationId, reasonForm, startAt, endAt }) => {

        const data = {
          locationId,
          reasonId: reasonForm.reason.Id,
          customPauseReason: reasonForm.comment,
          startDate: startAt,
          endDate: endAt,
        };

        return this.client.setSuspendAsset(data, accountId, {}).pipe(
          switchMap(asset => {

            return [
              AssetActions.addAsset({ asset }),
              SuspendActions.suspendAssetSuccess({
                accountId, locationId, reasonForm, startAt, endAt,
              }),
              // NotificationActions.success({
              //   message: 'Successfully set to suspend.',
              // }),
              // RouterActions.navigate({
              //   path: [ '/', accountId, 'manage', 'assets', locationId],
              // }),
            ];
          }),
          catchError(err => of(
            SuspendActions.suspendAssetFailure({
              accountId, locationId, reasonForm, startAt, endAt, err,
            }),
            NotificationActions.error({
              message: 'Cannot set to suspend.',
            }),
          )),
        );
      }),
    ),
  );

  handleSuspendAssetSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SuspendActions.suspendAssetSuccess,
      ),
      switchMap(({ accountId, locationId }) => {

        return [
          NotificationActions.success({
            message: 'Successfully set to suspend.',
          }),
          RouterActions.navigate({
            path: [ '/', accountId, 'manage', 'assets', locationId],
            query: { edit: Date.now() },
          }),
        ];
      }),
    ),
  );

  handleSuspendSample$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SuspendActions.suspendSample,
      ),
      switchMap(({ accountId, locationId, sample, reasonForm, startAt, endAt }) => {

        const data = {
          locationId,
          AnalysisType: sample.AnalysisType,
          SampleMethod: sample.SampleMethod,
          SamplePoint: sample.SamplePoint,
          reasonId: reasonForm.reason.Id,
          customPauseReason: reasonForm.comment,
          pauseStartDate: startAt,
          pauseEndDate: endAt,
        };

        return this.client.setSuspendSample(data, accountId, {}).pipe(
          switchMap(asset => {

            return [
              AssetActions.addAsset({ asset }),
              SuspendActions.suspendSampleSuccess({
                accountId, locationId, sample, reasonForm, startAt, endAt,
              }),
              NotificationActions.success({
                message: 'Successfully set to suspend.',
              }),
            ];
          }),
          catchError(err => of(
            SuspendActions.suspendSampleFailure({
              accountId, locationId, sample, reasonForm, startAt, endAt, err,
            }),
            NotificationActions.error({
              message: 'Cannot set to suspend.',
            }),
          )),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private client: ApxSolsticeWebClientService,
  ) {
  }

}
