import { HttpErrorResponse } from '@angular/common/http';
import { BatchTreatment, ContinuousTreatment, Sample, SuspendReasonForm } from '@apx-ui/apx-web-api-v1';
import { createAction, props } from '@ngrx/store';

export const suspendContinuousTreatment = createAction(
  '[APX Settings] Suspend Continuous Treatment',
  props<{
    accountId: string;
    locationId: string;
    treatment: ContinuousTreatment;
    reasonForm: SuspendReasonForm;
    startAt: string;
    endAt: string;
    unitSystemId?: string;
  }>(),
);

export const suspendContinuousTreatmentSuccess = createAction(
  '[APX Settings] Suspend Continuous Treatment Success',
  props<{
    accountId: string;
    treatment: ContinuousTreatment;
    reasonForm: SuspendReasonForm;
    startAt: string;
    endAt: string;
  }>(),
);

export const suspendContinuousTreatmentFailure = createAction(
  '[APX Settings] Suspend Continuous Treatment Failure',
  props<{
    accountId: string;
    treatment: ContinuousTreatment;
    reasonForm: SuspendReasonForm;
    startAt: string;
    endAt: string;
    err: HttpErrorResponse;
  }>(),
);

export const suspendBatchTreatment = createAction(
  '[APX Settings] Suspend Batch Treatment',
  props<{
    accountId: string;
    locationId: string;
    treatment: BatchTreatment;
    reasonForm: SuspendReasonForm;
    startAt: string;
    endAt: string;
    unitSystemId?: string;
  }>(),
);

export const suspendBatchTreatmentSuccess = createAction(
  '[APX Settings] Suspend Batch Treatment Success',
  props<{
    accountId: string;
    treatment: BatchTreatment;
    reasonForm: SuspendReasonForm;
    startAt: string;
    endAt: string;
  }>(),
);

export const suspendBatchTreatmentFailure = createAction(
  '[APX Settings] Suspend Batch Treatment Failure',
  props<{
    accountId: string;
    treatment: BatchTreatment;
    reasonForm: SuspendReasonForm;
    startAt: string;
    endAt: string;
    err: HttpErrorResponse;
  }>(),
);

export const suspendAsset = createAction(
  '[APX Settings] Suspend Asset',
  props<{
    accountId: string;
    locationId: string;
    reasonForm: SuspendReasonForm;
    startAt: string;
    endAt: string;
  }>(),
);

export const suspendAssetSuccess = createAction(
  '[APX Settings] Suspend Asset Success',
  props<{
    accountId: string;
    locationId: string;
    reasonForm: SuspendReasonForm;
    startAt: string;
    endAt: string;
  }>(),
);

export const suspendAssetFailure = createAction(
  '[APX Settings] Suspend Asset Failure',
  props<{
    accountId: string;
    locationId: string;
    reasonForm: SuspendReasonForm;
    startAt: string;
    endAt: string;
    err: HttpErrorResponse;
  }>(),
);

export const suspendSample = createAction(
  '[APX Settings] Suspend Sample',
  props<{
    accountId: string;
    locationId: string;
    sample: Sample;
    reasonForm: SuspendReasonForm;
    startAt: string;
    endAt: string;
  }>(),
);

export const suspendSampleSuccess = createAction(
  '[APX Settings] Suspend Sample Success',
  props<{
    accountId: string;
    locationId: string;
    sample: Sample;
    reasonForm: SuspendReasonForm;
    startAt: string;
    endAt: string;
  }>(),
);

export const suspendSampleFailure = createAction(
  '[APX Settings] Suspend Sample Failure',
  props<{
    accountId: string;
    locationId: string;
    sample: Sample;
    reasonForm: SuspendReasonForm;
    startAt: string;
    endAt: string;
    err: HttpErrorResponse;
  }>(),
);
